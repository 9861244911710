/* Global CSS Reset */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;900&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  color: #1E1E1E;
  letter-spacing: 0.2px;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  font-family: 'Pretendard';
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  line-height: 1;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}
a {
  text-decoration: none;
}
ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Global box-sizing reset */
* {
  box-sizing: border-box;
}
input, button, textarea { 
  border: 0;
  margin: 0;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../public/assets/fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../public/assets/fonts/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../public/assets/fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
